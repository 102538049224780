import { useContext } from "react"
import { MediaContext } from "../context/MediaContext"

const useMediaContext = () => {
    const { media, setMedia } = useContext(MediaContext)

    return { media, setMedia }
}

export default useMediaContext
