import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Icon from "../../../../ui/icons/Icon"
import { createGtmTagClass } from "../../../../../utils"
import dataAttributes from "../../../../../utils/dataAttributes"

import * as styles from "./styles"

const MenuItem = ({ label, url, onClick }) => {
    return (
        <li
            className={`${styles.MenuItem} ${createGtmTagClass("MenuElement")}`}
        >
            {url ? (
                <Link
                    {...dataAttributes("header-main-menu", label)}
                    className={styles.MenuItem__Link}
                    to={url.slice(0, -1)}
                    activeClassName={styles.MenuItem__LinkIsActive}
                    onClick={onClick}
                    partiallyActive
                >
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                    <Icon customClass={styles.MenuItem__Icon} icon="arrow2" />
                </Link>
            ) : (
                <div
                    {...dataAttributes("header-main-menu", label)}
                    onClick={onClick}
                    className={`${styles.MenuItem__Link} o-Link`}
                >
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                    <Icon customClass={styles.MenuItem__Icon} icon="arrow2" />
                </div>
            )}
        </li>
    )
}

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

export default MenuItem
