import React from "react"
import Analytics from "../components/functional/analytics/Analytics"

import Media from "../components/common/media/Media"

export const wrapPageElement = ({ element, props }) => {
    return (
        <>
            <Analytics pageProps={props} />

            {element}
            <Media />
        </>
    )
}
