import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Spreaker = ({ id }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    general {
                        acf {
                            generalPodcastBackground {
                                sourceUrl
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <iframe
            src={`https://widget.spreaker.com/player?episode_id=${id}&cover_image_url=${data.wp.general.acf.generalPodcastBackground.sourceUrl}`}
            width="100%"
            height="100%"
            frameBorder="0"
            title={id}
        />
    )
}

Spreaker.propTypes = {
    id: PropTypes.string.isRequired,
}

export default Spreaker
