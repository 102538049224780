import React from "react"

import Cookies from "../components/functional/cookies/Cookies"
import "../styles/styles.scss"
import { HubspotContextProvider } from "../context/HubspotContext"
import { PostsContextProvider } from "../context/PostsContext"
import { MediaContextProvider } from "../context/MediaContext"
import Header from "../components/layout/header/Header"
import Icons from "../icons/icons.svg"

export const wrapRootElement = ({ element }) => {
    return (
        <>
            <HubspotContextProvider>
                <MediaContextProvider>
                    <PostsContextProvider>
                        <Header />
                        {element}
                    </PostsContextProvider>
                </MediaContextProvider>
            </HubspotContextProvider>
            <Icons />

            <Cookies />
        </>
    )
}
