import { useEffect } from "react"
import { isBrowser } from "../../../../utils"
import PropTypes from "prop-types"

const AnalyticsPageInfo = ({ location, data, type }) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer ?? []
        window._mtm = window._mtm || []
    }

    useEffect(() => {
        const dataAnalytics = {
            event: "route-change",
            pageUrl: location,
            pageType: type,
            ...data,
        }

        window.dataLayer.push(dataAnalytics)
        window._mtm.push(dataAnalytics)
    }, [location, data, type])

    return null
}

AnalyticsPageInfo.propTypes = {
    location: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
}

export default AnalyticsPageInfo
