import { useStaticQuery, graphql } from "gatsby"

const useGetPosts = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpWebinar(limit: 999) {
                    nodes {
                        id
                        title
                        date
                        slug
                        tags {
                            nodes {
                                name
                            }
                        }
                        acf {
                            type
                            date
                            publicationdate
                            length
                            podcastid
                            videoid
                            length
                            image {
                                ...gatsbyFluidImage
                            }
                            imagethumbnail {
                                ...gatsbyFluidImage
                            }
                            seasonNumber
                        }
                    }
                }
                allWpEvent(limit: 999) {
                    nodes {
                        id
                        title
                        date
                        slug
                        content
                        acf {
                            type
                            date
                            publicationdate
                            place
                            recordings
                            image {
                                ...gatsbyFluidImage
                            }
                            imagethumbnail {
                                ...gatsbyFluidImage
                            }
                            materialsForm {
                                subheader
                                header
                                hubspotFormId
                                hubspotFormSlug
                                thankYouHeader
                                thankYouText
                            }
                        }
                    }
                }
            }
        `
    )
    const {
        allWpWebinar: { nodes: allInsights },
        allWpEvent: { nodes: allEvents },
    } = data

    const getLatest = array => {
        return array
            .sort((a, b) => {
                return new Date(a.acf.date) - new Date(b.acf.date)
            })
            .reverse()
            .slice(0, 3)
    }

    const filterByDate = array => {
        return array.filter(element => {
            if (element.acf.publicationdate) {
                return Date.parse(element.acf.publicationdate) <= Date.now()
            }
            return true
        })
    }

    return {
        insights: {
            all: filterByDate(allInsights),
            latests: getLatest(filterByDate(allInsights)),
        },
        events: {
            all: filterByDate(allEvents),
            latests: getLatest(filterByDate(allEvents)),
        },
    }
}

export default useGetPosts
