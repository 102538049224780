import roundUpToMultiplier from "./roundUpScrollStep"
import calculatePercentage from "./calculatePercentage"

/**
 * Reports scroll steps and calls passed function with the data
 *
 * @param {number[]} alreadyReported
 * @param {number} step
 * @param {function(data:object):void} callback
 * @returns {number[]}
 */

const handleScroll = (alreadyReported, callback, step) => {
    const pureArray = [...alreadyReported]

    if (window.scrollY === 0) {
        const data = {
            event: "ScrollTracking",
            scrollValue: 0,
        }

        !pureArray.includes(0) && pureArray.push(0) && callback(data)
    } else {
        const scrollProgress = roundUpToMultiplier(calculatePercentage(), step)

        const data = {
            event: "ScrollTracking",
            scrollValue: scrollProgress,
        }

        !pureArray.includes(scrollProgress) &&
            pureArray.push(scrollProgress) &&
            callback(data)
    }

    return pureArray
}
export default handleScroll
