import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"
import classNames from "classnames"

import Icon from "../icons/Icon"
import useMediaContext from "../../../hooks/useMediaContext"

const Modal = ({ children, onClose, active }) => {
    const { media, setMedia } = useMediaContext()
    const clickOutside = event => {
        if (event.target === event.currentTarget) {
            onClose()
        }
    }
    return (
        <div
            role="banner"
            onClick={event => clickOutside(event)}
            onKeyDown={event => clickOutside(event)}
            tabIndex={0}
            className={classNames(styles.Modal, {
                [styles.ModalIsActive]: active,
                [styles.ModalIsMinimized]: media.minimized,
            })}
        >
            <div
                className={styles.Modal__Toggler}
                onClick={() =>
                    setMedia(prev => {
                        return {
                            ...prev,
                            minimized: !prev.minimized,
                        }
                    })
                }
            >
                {media.minimized ? (
                    <Icon icon="maximized" />
                ) : (
                    <Icon icon="minimized" />
                )}
            </div>
            <div className={styles.Modal__Close} onClick={() => onClose()}>
                <Icon icon="times" />
            </div>

            <div className={`${styles.Modal__Container}`}>{children}</div>
        </div>
    )
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    active: PropTypes.bool,
}

export default Modal
