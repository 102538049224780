import React from "react"

import * as styles from "./styles"
import classnames from "classnames"

import Modal from "../../ui/modal/Modal"
import ReactPlayer from "react-player"
import useMediaContext from "../../../hooks/useMediaContext"
import Spreaker from "../../functional/spreaker/Spreaker"
import Icon from "../../ui/icons/Icon"

const Media = () => {
    const { media, setMedia } = useMediaContext()

    const closeMedia = () => {
        setMedia({ active: false, id: false })
    }

    return (
        <>
            {media.type === "video" ? (
                <Modal active={media.active} onClose={() => closeMedia()}>
                    <div
                        className={classnames(styles.Media, {
                            [styles.MediaIsMinimized]: media.minimized,
                        })}
                    >
                        <ReactPlayer
                            style={{
                                position: "absolute",
                                left: "0",
                                top: "0",
                            }}
                            playing
                            loop
                            width="100%"
                            height="100%"
                            controls
                            url={`https://www.youtube.com/embed/${media.id}`}
                        />
                    </div>
                </Modal>
            ) : (
                <>
                    {media.active && (
                        <div
                            className={classnames(styles.Media__Podcast, {
                                [styles.Media__PodcastMinimized]:
                                    media.minimized,
                            })}
                        >
                            <div
                                onClick={() =>
                                    setMedia(prev => {
                                        return {
                                            ...prev,
                                            minimized: !prev.minimized,
                                        }
                                    })
                                }
                                className={styles.Media__PodcastToggler}
                            >
                                {media.minimized ? (
                                    <Icon icon="maximized" />
                                ) : (
                                    <Icon icon="minimized" />
                                )}
                            </div>

                            <div
                                onClick={() => closeMedia()}
                                className={styles.Media__ClosePodcast}
                            >
                                <Icon icon="times" />
                            </div>
                            <Spreaker id={media.id} />
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default Media
