import React, { createContext } from "react"
import useGetPosts from "../hooks/useGetPosts"

export const PostsContext = createContext({})

export const PostsContextProvider = ({ children }) => {
    const { events, insights } = useGetPosts()
    return (
        <PostsContext.Provider
            value={{
                events,
                insights,
            }}
        >
            {children}
        </PostsContext.Provider>
    )
}
