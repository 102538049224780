import { useEffect } from "react"
import throttle from "../../../../utils/throttle"
import { isBrowser } from "../../../../utils"
import handleScroll from "../utils/handleScroll"

const AnalyticsScroll = () => {
    useEffect(() => {
        if (isBrowser()) {
            window.dataLayer = window.dataLayer ?? []
            window._mtm = window._mtm || []
        }

        // How scroll should be reported
        const report = (data) => {
            window.dataLayer.push(data) && window._mtm.push(data)
        }

        // Create array to make sure we report only new values
        let alreadyReported = []

        // Create function to be called upon end of every scroll
        const throttledFunction = throttle(() => {
            alreadyReported = handleScroll(alreadyReported, report, 25)
        }, 0)

        const data = {
            event: "ScrollTracking",
            scrollValue: 0,
        }

        // Initialize state
        !alreadyReported.includes(0) && alreadyReported.push(0) && report(data)

        // Add listener
        window.addEventListener("scroll", throttledFunction)

        // Remove listener
        return () => {
            window.removeEventListener("scroll", throttledFunction)
        }
    })

    return null
}

export default AnalyticsScroll
