import React, { useState, createContext } from "react"

export const MediaContext = createContext({})

export const MediaContextProvider = ({ children }) => {
    const [media, setMedia] = useState({
        active: false,
        id: false,
        type: undefined,
    })
    return (
        <MediaContext.Provider
            value={{
                media,
                setMedia,
            }}
        >
            {children}
        </MediaContext.Provider>
    )
}
