import { useEffect } from "react"
import * as CookieConsent from "vanilla-cookieconsent";
import en_data from "./en.json";

function getMainDomain(hostname) {
    const parts = hostname.split('.');

    if (parts.length > 2) {
        parts.shift();
    }

    return parts.join('.');
}

const CookieConsentComponent = () => {
    useEffect(() => {
        function gtag(){
            window.dataLayer.push(arguments);
        }

        gtag('consent', 'default', {
            'analytics_storage': 'denied',
            'functionality_storage': 'granted',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
        });
        
        gtag('set', 'ads_data_redaction', false);
        gtag('set', 'url_passthrough', true);
        
        gtag('js', new Date());

        function sendPreferences() {
            const categories = {
                analytics_storage: 'analytics',
                functionality_storage: 'necessary',
                personalization_storage: 'personalization',
                security_storage: 'necessary',
                ad_storage: 'marketing',
                ad_user_data: 'marketing',
                ad_personalization: 'marketing',
            };
        
            const consents = Object.entries(categories).reduce((acc, [key, category]) => {
                acc[key] = CookieConsent.acceptedCategory(category) ? 'granted' : 'denied';
                return acc;
            }, {});
        
            gtag('consent', 'update', consents);

            window.dataLayer.push({
                event: 'cc_info',
                cookies: CookieConsent.getUserPreferences().acceptedCategories.join(','),
            });
        
            ['analytics', 'personalization', 'marketing', 'necessary'].forEach(category => {
                if (CookieConsent.acceptedCategory(category)) {
                    const eventMap = {
                        analytics: 'cc_analytics',
                        personalization: 'cc_personalization',
                        marketing: 'cc_marketing',
                        necessary: 'cc_functionality'
                    };
                    window.dataLayer.push({
                        event: eventMap[category]
                    });
                }
            });
        }

        const fullDomain = window.location.hostname;
        const domain = getMainDomain(fullDomain);
        
        CookieConsent.run({
            guiOptions: {
                consentModal: {
                    layout: "cloud",
                    position: "middle center",
                    equalWeightButtons: false,
                    flipButtons: false
                },
                preferencesModal: {
                    layout: "bar wide",
                    position: "left",
                    equalWeightButtons: false,
                    flipButtons: false
                }
            },
            cookie: {
                name: 'fp_cc_itinsights',
                domain: domain,
                path: '/',
                expiresAfterDays: 365,
                sameSite: 'Lax',
                useLocalStorage: false
            },
            manageScriptTags: false,
                disablePageInteraction: true,
                categories: {
                    necessary: {
                        enabled: true,
                        readOnly: true
                    },
                    functionality: {},
                    analytics: {},
                    personalization: {},
                    marketing: {}
                },
        
            onConsent: function () {
                sendPreferences();
            },
        
            onChange: function () {
                sendPreferences();
            },
        
            language: {
                default: 'en',
            translations: {
              en: en_data,
            },
          },
        });
    }, []);

    return null;
}

export default CookieConsentComponent
