import React from "react"
import Gtm from "./gtm/Gtm"
import AnalyticsPageInfo from "./analytics-page-info/AnalyticsPageInfo"
import AnalyticsScroll from "./analitycs-scroll/AnalitycsScroll"
import { graphql, useStaticQuery } from "gatsby"

const Analytics = ({ pageProps }) => {
    const dataAnalytics = useStaticQuery(
        graphql`
            query {
                wp {
                    analytics {
                        acf {
                            gtmId,
                        }
                    }
                }
            }
        `
    )

    const {
        gtmId,
    } = dataAnalytics.wp.analytics.acf

    const type = pageProps.data ? pageProps.pageContext.pageType : "404"

    let title
    switch (type) {
        case "event":
            title = pageProps.data.wpEvent.title
            break
        case "webinar":
            title = pageProps.data.wpWebinar.title
            break
        case "basic-page":
            title = pageProps.data.wpPage.acfMainPage.title
            break
        default:
            break
    }
    // Create analytics data
    const data = {
        pageTitle: title,
    }

    return (
        <>
            <Gtm Id={gtmId} />
            <AnalyticsPageInfo
                location={pageProps.uri}
                data={data}
                type={type}
            />
            <AnalyticsScroll />
        </>
    )
}

export default Analytics
