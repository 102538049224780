import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as styles from "./styles"
import classnames from "classnames"

import PropTypes from "prop-types"
import Icon from "../icons/Icon"
import dataAttributes from "../../../utils/dataAttributes"

const SocialIcons = ({ dataElemClick, stacked }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    socialmedia {
                        acf {
                            socialmediatitle
                            socialmedia {
                                name
                                link
                                icon
                            }
                        }
                    }
                }
            }
        `
    )
    const socialmediaAcf = data.wp.socialmedia.acf
    const socials = socialmediaAcf.socialmedia
    const title = socialmediaAcf.socialmediatitle
    return (
        <div
            className={classnames(styles.SocialIcons, {
                [styles.SocialIconsStacked]: stacked,
            })}
        >
            <span className={styles.SocialIcons__Text}>{title}</span>
            <ul className={styles.SocialIcons__Container}>
                {socials?.map((social, index) => (
                    <li key={index}>
                        <a
                            rel="noreferrer noopener nofollow"
                            target="_blank"
                            href={social.link}
                            {...dataAttributes(dataElemClick, social.icon)}
                        >
                            <Icon
                                icon={social.icon}
                                customClass={styles.SocialIcons__Icon}
                            />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

SocialIcons.propTypes = {
    dataElemClick: PropTypes.string,
    stacked: PropTypes.bool,
}

export default SocialIcons
