/**
 * Simple throttle function to call function only after each interval
 *
 * @param {function} callback callback after inerval
 * @param {number} interval number in milliseconds after which callback will be called
 */
const throttle = (callback, interval) => {
    let timeout

    return () => {
        clearTimeout(timeout)
        timeout = setTimeout(callback, interval)
    }
}
export default throttle
