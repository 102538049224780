import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import dataAttributes from "../../../../utils/dataAttributes"

import * as styles from "./styles"
import classNames from "classnames"

const Hamburger = ({ isActive, onClick, modal, dataAtt }) => {
    const data = useStaticQuery(graphql`
        query {
            wp {
                navigation {
                    acf {
                        navigationCloseMenu
                        navigationOpenMenu
                    }
                }
            }
        }
    `)
    const { navigationCloseMenu, navigationOpenMenu } = data.wp.navigation.acf
    const hamburgerText = isActive ? navigationCloseMenu : navigationOpenMenu
    return (
        <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={0}
            {...(dataAtt
                ? { ...dataAttributes(dataAtt.click, dataAtt.name) }
                : null)}
            className={classNames(styles.Hamburger, {
                [styles.HamburgerIsActive]: isActive,
                [styles.HamburgerModal]: modal,
            })}
        >
            <span />
            <span />
            <span />
            <span />
            <p className={styles.Hamburger__Text}>{hamburgerText}</p>
        </div>
    )
}

Hamburger.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    modal: PropTypes.bool,
    dataAtt: PropTypes.object,
}

export default Hamburger
