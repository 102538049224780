/**
 * Rounds up number up to any precision
 *
 * @param {number} number
 * @param {number}division
 * @returns {number}
 */

const roundUpScrollStep = (number, division) => {
    // Get rest of division
    const rest = number % division

    // Get full multipliers
    const fullDivisions = Math.floor(number / division)

    // Round up
    return rest >= Math.floor(division / 2)
        ? (fullDivisions + 1) * division
        : fullDivisions * division
}
export default roundUpScrollStep
