import React, { createContext, useEffect, useState } from "react"

export const HubspotContext = createContext({})

export const HubspotContextProvider = ({ children }) => {
    const [hubspot, setHubspot] = useState(null)

    useEffect(() => {
        const hbsptScript = document.createElement("script")
        hbsptScript.src = "//js.hsforms.net/forms/v2.js"
        hbsptScript.async = true
        hbsptScript.onload = () => {
            /* eslint-disable no-undef */
            setHubspot(hbspt)
        }
        document.body.appendChild(hbsptScript)
    })

    return (
        <HubspotContext.Provider value={hubspot}>
            {children}
        </HubspotContext.Provider>
    )
}
