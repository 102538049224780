/**
 * Calculates percentage offset from top of the window to the bottom of
 * the window
 * @returns {number}
 */

const calculatePercentage = () => {
    return Math.floor(
        ((window.scrollY + window.innerHeight) / document.body.offsetHeight) *
            100
    )
}
export default calculatePercentage
