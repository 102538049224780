import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import * as styles from "./styles"
import classNames from "classnames"

import dataAttributes from "../../../utils/dataAttributes"
import throttle from "../../../utils/throttle"

import Hamburger from "./hamburger/Hamburger"
import Navigation from "./navigation/Navigation"
import logo from "../../../images/iti-logo.svg"

const Header = () => {
    const [stickyHeader, setStickyHeader] = useState(false)
    const [menuActive, setMenuActive] = useState(false)

    useEffect(() => {
        window.addEventListener("resize", throttle(calcViewport, 200))
        calcViewport()

        return () => {
            window.removeEventListener("resize", throttle(calcViewport, 200))
        }
    }, [])

    useEffect(() => {
        menuActive
            ? document.body.classList.add("Is-MenuOpen")
            : document.body.classList.remove("Is-MenuOpen")
        window.addEventListener("scroll", throttle(handlePageScroll, 200))

        return () => {
            window.removeEventListener(
                "scroll",
                throttle(handlePageScroll, 200)
            )
        }
    }, [menuActive])
    const handlePageScroll = () => {
        window.scrollY > 10 ? setStickyHeader(true) : setStickyHeader(false)
    }
    const calcViewport = () => {
        document.documentElement.style.setProperty(
            "--viewport-height",
            `${window.innerHeight}px`
        )
    }
    return (
        <header
            className={classNames(styles.Header, {
                [styles.HeaderIsSticky]: stickyHeader,
            })}
        >
            <div className={styles.Header__Inner + " l-Inner"}>
                <Link
                    {...dataAttributes("header-main-logo", "IT Insights logo")}
                    to="/"
                >
                    <img
                        className={styles.Header__Logo}
                        src={logo}
                        alt="IT Insights"
                    />
                </Link>
                <Navigation
                    stickyHeader={stickyHeader}
                    activeMenu={menuActive}
                    closeMenu={() => setMenuActive(false)}
                />
                <Hamburger
                    onClick={() => setMenuActive(!menuActive)}
                    isActive={menuActive}
                    dataAtt={{
                        click: "header-mobilemenu-btn-close",
                        name: "Close menu",
                    }}
                />
            </div>
        </header>
    )
}

export default Header
