import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import MenuItem from "../menuItem/MenuItem"
import * as styles from "./styles"

const Menu = ({ closeMenu }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wpMenu(locations: { eq: MENU_MAIN }) {
                    menuItems {
                        nodes {
                            id
                            label
                            url
                        }
                    }
                }
                wp {
                    navigation {
                        acf {
                            navigationContactMenu
                        }
                    }
                }
            }
        `
    )
    const {
        menuItems: { nodes: menuItems },
    } = data.wpMenu
    const {
        navigation: {
            acf: { navigationContactMenu },
        },
    } = data.wp

    const scrollToContact = () => {
        const section = document.querySelector("#contact")
        if (!section) return
        closeMenu()
        section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    return (
        <ul className={styles.Menu}>
            {menuItems?.map(({ id, label, url }) => (
                <MenuItem
                    onClick={closeMenu}
                    key={id}
                    label={label}
                    url={url}
                />
            ))}
            <MenuItem onClick={scrollToContact} label={navigationContactMenu} />
        </ul>
    )
}

Menu.propTypes = {
    closeMenu: PropTypes.func.isRequired,
}

export default Menu
