import React from "react"
import { Helmet } from "react-helmet"
import { isBrowser } from "../../../../utils"
import PropTypes from "prop-types"

const Gtm = ({ Id }) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer ?? []
        window.dataLayer.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime(),
        })
    }

    return (
        <div>
            <Helmet>
                <script
                    src={`https://www.googletagmanager.com/gtm.js?id=${Id}`}
                    async
                />
            </Helmet>

            <div
                dangerouslySetInnerHTML={{
                    __html: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${Id}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
                }}
            />
        </div>
    )
}

Gtm.propTypes = {
    Id: PropTypes.string.isRequired,
}

export default Gtm
