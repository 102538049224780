import React from "react"
import * as styles from "./styles"
import PropTypes from "prop-types"
import classNames from "classnames"

import Menu from "./menu/Menu"
import SocialIcons from "../../../ui/social-icons/SocialIcons"

const Navigation = ({ activeMenu, closeMenu, stickyHeader }) => {
    return (
        <nav
            className={classNames(styles.Navigation, {
                [styles.NavigationIsActive]: activeMenu,
                [styles.NavigationIsHigher]: stickyHeader,
            })}
        >
            <Menu closeMenu={closeMenu} />
            <div className={styles.Navigation__SocialIcons}>
                <SocialIcons
                    stacked
                    dataElemClick="header-mobilemenu-btn-social"
                />
            </div>
        </nav>
    )
}

Navigation.propTypes = {
    activeMenu: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    stickyHeader: PropTypes.bool.isRequired,
}

export default Navigation
